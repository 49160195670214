var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Search")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)]),_c('b-form-group',[_c('b-button',{staticClass:"ml-1 d-inline-block",attrs:{"to":{ name: 'create_product' },"variant":"gradient-primary"}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"ml-1"},[_vm._v("Create product")])],1)],1)],1),(_vm.load === 'true')?_c('div',{staticClass:"text-center mb-2"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Text Centered"}})],1):_vm._e(),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.products,"rtl":_vm.direction,"search-options":{
      enabled: true,
      externalQuery: _vm.searchTerm }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'fullName')?_c('div',{staticClass:"text-nowrap"},[_c('b-avatar',{staticClass:"mx-1",attrs:{"src":props.row.avatar}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.fullName))])],1):(props.column.field === 'status')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.status)}},[_vm._v(" "+_vm._s(props.row.status)+" ")])],1):(props.column.field === 'action')?_c('span',[_c('span',[_c('feather-icon',{staticClass:"cursor-pointer mr-1",attrs:{"id":("invoice-row-" + (props.row.id) + "-edit-icon"),"icon":"EditIcon","size":"16"},on:{"click":function($event){return _vm.$router.push({
                name: 'edit-product',
                params: { id: props.row.id} ,
              })}}}),_c('b-tooltip',{staticClass:"cursor-pointer",attrs:{"title":"Update","target":("invoice-row-" + (props.row.id) + "-edit-icon")}}),(props.row.stock_in_way_tunisia === 0 && props.row.stock_in_way_brazil === 0 && props.row.stock_received_brazil === 0 && props.row.stock_received_tunisia === 0)?_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"id":("invoice-row-" + (props.row.id) + "-trash-icon"),"icon":"TrashIcon","size":"16"},on:{"click":function($event){return _vm.deleteProduct(props.row)}}}):_vm._e(),_c('b-tooltip',{staticClass:"cursor-pointer",attrs:{"title":"Delete","target":("invoice-row-" + (props.row.id) + "-trash-icon")}}),_c('feather-icon',{staticClass:"cursor-pointer ml-1",attrs:{"id":("invoice-row-" + (props.row.id) + "-show-icon"),"icon":"EyeIcon","size":"16"},on:{"click":function($event){return _vm.$router.push({
                name: 'show-product',
                params: { id: props.row.id} ,
              })}}}),_c('b-tooltip',{staticClass:"cursor-pointer",attrs:{"title":"Detail","target":("invoice-row-" + (props.row.id) + "-show-icon")}})],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }