<template>
  <div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
      <b-form-group>
        <b-button
          :to="{ name: 'create_product' }"
          variant="gradient-primary"
          class="ml-1 d-inline-block"
        >
          <feather-icon icon="PlusIcon" />
          <span class="ml-1">Create product</span>
        </b-button>
      </b-form-group>
    </div>
    <div
      v-if="load === 'true'"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="products"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <feather-icon
              :id="`invoice-row-${props.row.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
              @click="
                $router.push({
                  name: 'edit-product',
                  params: { id: props.row.id} ,
                })
              "
            />
            <b-tooltip
              title="Update"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />

            <feather-icon
              v-if="props.row.stock_in_way_tunisia === 0 && props.row.stock_in_way_brazil === 0 && props.row.stock_received_brazil === 0 && props.row.stock_received_tunisia === 0"
              :id="`invoice-row-${props.row.id}-trash-icon`"
              icon="TrashIcon"
              class="cursor-pointer"
              size="16"
              @click="deleteProduct(props.row)"
            />
            <b-tooltip
              title="Delete"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-trash-icon`"
            />

            <feather-icon
              :id="`invoice-row-${props.row.id}-show-icon`"
              icon="EyeIcon"
              class="cursor-pointer ml-1"
              size="16"
              @click="
                $router.push({
                  name: 'show-product',
                  params: { id: props.row.id} ,
                })
              "
            />
            <b-tooltip
              title="Detail"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-show-icon`"
            />
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BFormGroup, BFormInput, BFormSelect, BButton,
  BSpinner, BTooltip,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { onMounted } from '@vue/composition-api'
import store from '@/store/index'
import useProduct from './useProduct'
import axiosIns from '@/libs/axios'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BSpinner,
    BTooltip,
  },
  data() {
    return {
      load: 'false',
      pageLength: 10,
      dir: false,
      searchTerm: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {
    async deleteProduct(product) {
      const result = await this.$swal({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes I want to delete this product',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
      this.load = 'true'
      if (result.value) {
        try {
          await axiosIns.put(`/products/update/${product.id}/`, {
            is_deleted: true,
          })
          this.load = 'false'
          this.getProducts()
          this.showToast('success', 'top-center', 'Product successfully deleted')
        } catch (error) {
          this.load = 'false'
          this.showToast('danger', 'top-center', error.toString())
        }
      } else if (result.dismiss === 'cancel') {
        this.load = 'false'
        this.$swal({
          title: 'Close',
          text: 'Product Not Deleted',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }
    },
    // toast
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
  setup() {
    const { products, getProducts, columns } = useProduct()

    onMounted(() => {
      getProducts()
    })

    return {
      products,
      getProducts,
      columns,
    }
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
